import { addDays, intervalToDuration, formatDuration } from 'date-fns';

export function convertStringToTitleCase(string: string) {
	return string.replace(
		/\w\S*/g,
		(text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
	);
}

export function formatPrice(startWeightGrams: number, pricePerGram: number) {
	const result = startWeightGrams * pricePerGram;
	const roundedResult = Math.round(result * 100) / 100;

	if (Number.isInteger(roundedResult)) {
		return Number(roundedResult);
	} else {
		return parseFloat(roundedResult.toFixed(2));
	}
}

export function convertPricingTierOption(startWeightGrams: number | null) {
	if (startWeightGrams === null) return 'N/A';
	if (startWeightGrams < 1) return `${startWeightGrams * 1000} mg`;
	const gramsOptionMap: { [key: number]: string } = {
		1: '1g',
		1.5: '1.5g',
		3.5: '1/8oz',
		5: '5g',
		7: '1/4oz',
		10: '10g',
		14: '1/2oz',
		20: '20g',
		28: '1oz',
	};
	return gramsOptionMap[startWeightGrams] || `${startWeightGrams}g`;
}

export function percentageStringToNumber(inputString: string) {
	const regex = /[^0-9.]/g;
	const numberString = inputString.replace(regex, '');
	return parseFloat(numberString);
}

// Used for dashboard forecasts
export function formatDaysToReadableString(days: number) {
	if (days === 0) {
		return '< 1 day';
	}
	const now = new Date();
	const futureDate = addDays(now, days);
	const duration = intervalToDuration({ start: now, end: futureDate });

	return formatDuration(duration, { format: ['years', 'months', 'days'] });
}

function toCamelCase(str: string): string {
	return str.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
}

function toSnakeCase(str: string): string {
	return str.replace(/([A-Z])/g, (matches) => `_${matches[0].toLowerCase()}`);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertObjectKeys(obj: any, transformKey: (key: string) => string): any {
	if (Array.isArray(obj)) {
		return obj.map((item) => convertObjectKeys(item, transformKey));
	} else if (typeof obj === 'object' && obj !== null) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const result: { [key: string]: any } = {};

		for (const key in obj) {
			const newKey = transformKey(key);
			result[newKey] = convertObjectKeys(obj[key], transformKey);
		}

		return result;
	} else {
		return obj;
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertObjectKeysToCamelCase(obj: any): any {
	return convertObjectKeys(obj, toCamelCase);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertObjectKeysToSnakeCase(obj: any): any {
	return convertObjectKeys(obj, toSnakeCase);
}

export function camelToTitleCase(string: string) {
	const words = string.split(/(?=[A-Z]+)/);

	const firstWord = words.shift();
	if (!firstWord) return string;

	const firstWorldCapitalized = firstWord[0].toUpperCase() + firstWord.slice(1);

	return [firstWorldCapitalized, ...words].join(' ');
}
